/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

"use client"

import React, { useState, useCallback, useEffect } from 'react';

import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useDropzone } from 'react-dropzone';
import { FileIcon, defaultStyles } from "react-file-icon";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import axios from 'axios';
import consoleLogger from 'src/helpers/utilities/consoleLogger';
import styles from './AssetUpload.module.scss';
import Table from 'src/components/modules/Table';
import Help from 'src/components/modules/Help';

function AssetUpload() {
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [acceptedFilesExist, setAcceptedFilesExist] = useState([]);
    const [droppedFilesExist, setDroppedFilesExist] = useState([]);
    const [progress, setProgress] = useState("0%");
    const [cancelToken, setCancelToken] = useState(null);
    const [uploading, setUploading] = useState(false);
    const imageTypes = ["jpg", "jpeg", "png", "png", "gif"];
    const removePrefixes = ["cors_", "_c", "_sol", "_pre"];
    const { data: session } = useSession();

    const loadPermittedFeatures = async () => {
        try {
            const response = await axios.get(`api/features`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.NEXT_PUBLIC_API_SUBSCRIPTION_KEY,
                },
            });

            if (response && response.status === 200) {
                return response.data.message;
            }
            return response;

        } catch (error) {

            consoleLogger('Error getting features', error);

            return error;
        }
    }

    const onDrop = useCallback(async (droppedFiles) => {
        const newAcceptedFiles = [];
        const newRejectedFiles = [];
        const allowedFeatures = await loadPermittedFeatures();

        setDroppedFilesExist(droppedFiles.length > 0);

        droppedFiles.forEach((file) => {
            const fileName = file.name;

            // Get current date
            const today = new Date();
            const month = today.getMonth() + 1;
            const year = today.getFullYear();
            const date = today.getDate();
            const currentDate = `${month}/${date}/${year}`;

            const validFileName2DigitYear = /^([^0-9\s])+\d{6}[^0-9\s]*\.[A-Za-z]+$/.test(fileName);
            const validFileName4DigitYear = /^([^0-9\s])+\d{8}[^0-9\s]*\.[A-Za-z]+$/.test(fileName);
            const validFileNameDateSpan2DY = /^([^0-9\s])+\d{6}[^0-9\s]+([0-9])*\.[A-Za-z]+$/.test(fileName);
            const validFileNameDateSpan4DY = /^([^0-9\s])+\d{8}[^0-9\s]+([0-9])*\.[A-Za-z]+$/.test(fileName);
            const validFileNameYear = validFileName2DigitYear || validFileName4DigitYear || validFileNameDateSpan2DY || validFileNameDateSpan4DY;

            const validFileNameYearFirst = /^20\d{6}[^0-9\s]+\S\.[^0-9]+$/.test(fileName);
            const validFileName = validFileNameYear || validFileNameYearFirst;


            let fileFeature;
            let allowedFeature;
            if (validFileNameYear) {
                fileFeature = fileName.match(/^(cor_)?[^0-9]+/)[0];

                removePrefixes.forEach(prefix => {
                    fileFeature = fileFeature.replace(prefix, "");
                });

                allowedFeature = allowedFeatures.includes(fileFeature);
            }
            else if (validFileNameYearFirst) {
                fileFeature = fileName.match(/(?<=[0-9])[A-Za-z]+/)[0];

                removePrefixes.forEach(prefix => {
                    fileFeature = fileFeature.replace(prefix, "");
                });

                allowedFeature = allowedFeatures.includes(fileFeature);
            }
            else {
                allowedFeature = true;
            }

            const zeroPad = (num) => String(num).padStart(2, '0');

            const fileDateFound = fileName.match(/[0-9]+/);
            const fileDate = fileDateFound != null ? fileDateFound[0] : "";

            let dateString = today;
            if (fileDate.length == 6) {
                const y = parseInt(fileDate.slice(0, 2), 10) + 2000;
                const m = parseInt(fileDate.slice(2, 4), 10);
                const d = parseInt(fileDate.slice(4, 6), 10);
                dateString = y.toString() + "-" + zeroPad(m.toString()) + "-" + zeroPad(d.toString());
            }
            else if (fileDate.length == 8) {
                const y = parseInt(fileDate.slice(0, 4), 10);
                const m = parseInt(fileDate.slice(4, 6), 10);
                const d = parseInt(fileDate.slice(6, 8), 10);
                dateString = y.toString() + "-" + zeroPad(m.toString()) + "-" + zeroPad(d.toString());
            }

            const validDate = !isNaN(Date.parse(dateString));

            const excludedFileType = /(\.|\/)(386|ade|adp|app|asp|bas|bat|cer|cgi|chm|cmd|com|cpl|crt|csh|csr|dll|db|drv|exe|fxp|hlp|hta|htaccess|htpasswd|inf|ins|isp|jar|js|jse|jsp|ksh|lnk|mdb|mde|mdt|mdw|msc|msi|msp|mst|ops|pcd|php([0-9])?|pif|pl|prg|reg|scr|sct|sh|shb|shs|sys|torrent|url|vb|vbe|vbs|vbscript|wsc|wsf|wsh)$/i.test(fileName);

            if (validFileName && allowedFeature && validDate && !excludedFileType) {
                const existingFileIndex = acceptedFiles.findIndex((existingFile) => existingFile.name === fileName);
                const acceptedFile = file;
                acceptedFile.dateAdded = currentDate;
                acceptedFile.status = "Ready to upload"
                if (existingFileIndex !== -1) {
                    // Replace the existing file
                    setAcceptedFiles((prevFiles) => [
                        ...prevFiles.slice(0, existingFileIndex),
                        file,
                        ...prevFiles.slice(existingFileIndex + 1),
                    ]);
                } else {
                    newAcceptedFiles.push(file);
                }

            } else {

                const existingFileIndex = rejectedFiles.findIndex((existingFile) => existingFile.name === fileName);
                const rejectedFile = file;
                rejectedFile.error = "";
                if (!validFileName) rejectedFile.error += "Invalid file name. ";
                if (excludedFileType) rejectedFile.error += "Invalid file type. ";
                if (!validDate) rejectedFile.error += "Invalid date. ";
                if (!allowedFeature) rejectedFile.error += "Uploading to this feature is not allowed or feature not found. ";
                rejectedFile.dateAdded = currentDate;
                if (existingFileIndex !== -1) {
                    // Replace the existing file
                    setRejectedFiles((prevFiles) => [
                        ...prevFiles.slice(0, existingFileIndex),
                        rejectedFile,
                        ...prevFiles.slice(existingFileIndex + 1),
                    ]);
                } else {

                    newRejectedFiles.push(rejectedFile);
                }
            }
        });

        setAcceptedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, ...newAcceptedFiles];
            setAcceptedFilesExist(updatedFiles.length > 0);
            return Array.from(new Set(updatedFiles)).sort(); // Remove duplicates
        });
        setRejectedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, ...newRejectedFiles];
            return Array.from(new Set(updatedFiles)).sort(); // Remove duplicates
        });

    }, [acceptedFiles, rejectedFiles]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const loadExistingFiles = async () => {
        try {

            const response = await axios.get(`api/assets`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.NEXT_PUBLIC_API_SUBSCRIPTION_KEY,
                },
            });

            if (response && response.status === 200) {
                const files = response.data.message;
                setExistingFiles(files);

                return files;
            }

            return response;

        } catch (error) {
            consoleLogger("loadExistingFiles error:", error);
            return error;
        }
    }

    const uploadToAzure = async () => {
        const formData = new FormData();

        const fileArray = acceptedFiles;

        setUploading(true);

        fileArray.forEach(file => {
            formData.append("files", file);
            formData.append("email", session.user.email);
        });

        const source = axios.CancelToken.source();
        setCancelToken(source);

        const acceptedFilesStatusUpdate = acceptedFiles.map((file) => {
            const f = file;
            f.status = "Uploading...";
            return file;
        });

        setAcceptedFiles(acceptedFilesStatusUpdate);

        try {
            const response = await axios.post(`${process.env.NEXT_PUBLIC_SERVICE_OPAL_URL}/assets`, formData, {
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.NEXT_PUBLIC_API_SUBSCRIPTION_KEY,
                },
                onUploadProgress: (progressEvent) => {

                    const { loaded, total } = progressEvent;

                    const percentCompleted = Math.round((loaded * 100) / total);

                    setProgress(percentCompleted < 100 ? `${percentCompleted}%` : "Complete!");
                },
                cancelToken: source.token,
            });


            if (response && response.status === 200) {
                await loadExistingFiles();
                setAcceptedFiles([]);
                setAcceptedFilesExist(false);
            }
            return response;

        } catch (error) {

            if (axios.isCancel(error)) {
                consoleLogger('Upload canceled', error.message);
            } else {
                consoleLogger('Error uploading file', error);
            }
            return error;
        } finally {

            setUploading(false);
        }


    }

    const cancelUpload = () => {
        if (cancelToken) {
            cancelToken.cancel('Upload canceled by user');

            const acceptedFilesStatusUpdate = acceptedFiles.map((file) => {
                const f = file;
                f.status = "Upload canceled";
                return file;
            });

            setAcceptedFiles(acceptedFilesStatusUpdate);
        }
    };

    const removeFile = (file, list, setList, setExist) => {
        const newList = list.filter((item) => item !== file);
        setList(newList);

        if (setExist) {
            setAcceptedFilesExist(newList.length > 0);
        }
    };

    const previewIconLocal = (file) => {
        const fileExt = file.name.split('.').pop();

        let img;
        if (imageTypes.includes(fileExt)) {
            img = (<Zoom>
                <img
                    alt={file.name}
                    height="50"
                    src={URL.createObjectURL(file)}
                />
            </Zoom>);
        }
        else {
            img = (<div className={styles.icon}>
                <FileIcon extension={fileExt}  {...defaultStyles[fileExt]} />
            </div>
            );
        }

        return img;

    };

    const formatFileSize = (size) => {
        const sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / (1024 ** i)).toFixed(2)} ${sizes[i]}`
    };

    useEffect(() => {
        setDroppedFilesExist(false);
        loadExistingFiles();

    }, []);

    return (
        <div>
            {session ?
                (
                    <div className={styles.container}>

                        <h2>Upload Your Content</h2>
                        <p>You can drag and drop or select files to upload in the section below. you can drag and drop or select files to upload. Files must be less than 50MB. Image files should be at least 600 dpi. Commonly accepted formats include .jpeg, .tif, .png, .zip, .xml, and .json. File formats including .exe, .bat, and .torrent are prohibited to protect system integrity.</p>

                        <p>Please follow the naming conventions below for file processing and identification:</p>

                        <ul>
                            <li><b>Short Date Format:</b> feature code (xyz), year (yy), month (mm), day (dd) Ex: xyz240501.tif</li>

                            <li><b>Long Date Format:</b> feature code (xyz), year (yyyy), month (mm), day (dd) Ex: xyz20240501.tif</li>
                        </ul>

                        <p>For issues, check our FAQs or contact <a href="mailto:opal_support@amuniversal.com" title="opal_support@amuniversal.com">OPAL support</a>.</p>
                        <br />
                        <div className={isDragActive ? styles.dropboxDrag : styles.dropbox}>
                            <div {...getRootProps()} className={styles.drop}>
                                <Image
                                    alt="Drop Files"
                                    height={100}
                                    priority
                                    src="/images/upload.svg"
                                    width={100}
                                />
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p>}
                            </div>
                            <div className={styles.uploadListTableDiv}>
                                <table className={droppedFilesExist ? styles.uploadListTable : styles.hideUploadListTable}>
                                    <thead>
                                        <tr>
                                            <th className={styles.preview}>Preview</th>
                                            <th className={styles.filename}>File Name</th>
                                            <th className={styles.dateAdded}>Date Added</th>
                                            <th className={styles.dateAdded}>Size</th>
                                            <th>Status</th>
                                            <th className={styles.action}>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rejectedFiles.map((file) => (
                                            <tr className={styles.error} key={file.name} >
                                                <td>{previewIconLocal(file)}</td>
                                                <td className={styles.field}>{file.name}</td>
                                                <td className={styles.field}>{file.dateAdded}</td>
                                                <td className={styles.field}>{formatFileSize(file.size)}</td>
                                                <td className={styles.field}>Not ready: {file.error}</td>
                                                <td>
                                                    <Image
                                                        alt="Delete"
                                                        className={styles.actionImg}
                                                        height={15}
                                                        src="/images/trash.png"
                                                        width={15}
                                                        onClick={() => removeFile(file, rejectedFiles, setRejectedFiles)}
                                                    />

                                                </td>
                                            </tr>
                                        ))}
                                        {acceptedFiles.map((file) => (
                                            <tr className={styles.ready} key={file.name}>
                                                <td>{previewIconLocal(file)}</td>
                                                <td className={styles.field}>{file.name}</td>
                                                <td className={styles.field}>{file.dateAdded}</td>
                                                <td className={styles.field}>{formatFileSize(file.size)}</td>
                                                <td className={styles.field}>{file.status}</td>
                                                <td>
                                                    <Image
                                                        alt="Delete"
                                                        className={styles.actionImg}
                                                        height={15}
                                                        src="/images/trash.png"
                                                        width={15}
                                                        onClick={() => removeFile(file, acceptedFiles, setAcceptedFiles, setAcceptedFilesExist)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className={styles.btnRow} colSpan="5">
                                                <button className={acceptedFilesExist && !uploading ? styles.btn : styles.btnDisabled} type="button" onClick={uploadToAzure}>Upload Ready Files</button>
                                                <button className={uploading ? styles.btn : styles.btnDisabled} type="button" onClick={cancelUpload}>Cancel</button>



                                                <p className={styles.progress}>{uploading && <span>Progress: {progress}</span>}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>

                            <Table data={existingFiles} />

                        </div>
                    </div>
                ) : (
                    <div className={styles.drop}>
                        <div className={styles.dropPad}>
                            Welcome to AMU Opal. You are currently signed out. If you are not automatically redirected to the sign-in page, click the Sign In button above to continue.
                        </div>
                    </div>
                )
            }
            <Help />
        </div >
    );


};

export default AssetUpload;