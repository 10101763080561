import React from 'react';
import { useSession, status } from 'next-auth/react';
import Image from 'next/image';
import { userSignIn, userSignOut } from 'src/helpers/utilities/auth/azureConnect';
import Heading from 'src/components/commons/Heading';
import styles from './ShowSiteHeader.module.scss';

function ShowSiteHeader() {
    // const { data: session } = useSession();

    const { data: session } = useSession({
        required: true,
        onUnauthenticated() {
            userSignIn();
        },
    })

    return (
        <header className={styles.header} >
            <Image
                alt="Opal Logo"
                height={60}
                priority
                src="https://cisassets.blob.core.windows.net/opal-page-assets/Opal_temp_Logo_150px.png"
                width={62}
            />
            <Heading>
                {session ?
                    (
                        <button className={styles.btnSignIn} type="button" onClick={() => userSignOut()}>Sign out</button>
                    ) : (
                        <button className={styles.btnSignIn} type="button" onClick={() => userSignIn()}>Sign in</button>
                    )
                }
            </Heading>
        </header>
    );
}

export default ShowSiteHeader;
