import React from 'react';

import PropTypes from 'prop-types';

import styles from './Heading.module.scss';

function Heading({ children }) {
    return <h1 className={styles.heading}>{children}</h1>;
}

Heading.propTypes = {
    children: PropTypes.node.isRequired,

};



export default Heading;
