"use client"

import React from 'react';
import { SessionProvider } from 'next-auth/react';
import ShowSiteHeader from 'src/components/sections/ShowSiteHeader';
import AssetUpload from 'src/components/modules/AssetUpload';
import { B2C } from 'src/constants';

function HomePage() {
    return (
        <SessionProvider
            // Refetch the session when it's ready to expire, which will trigger a refresh
            // It also will refetch when the window is focused
            refetchInterval={B2C.ID_TOKEN_AGE}
            // Disable session refetch if the user is offline, otherwise it returns an invalid session
            refetchWhenOffline={false}
        >
            
            <main>
                
                <ShowSiteHeader />
                <AssetUpload />

            </main>
        </SessionProvider>
    );
}

export default HomePage;
