import React from 'react';
import Popup from 'reactjs-popup';
import './Help.css'
import styles from './Help.module.scss';


function Help() {

    return (
        <Popup trigger={<button className={styles.helpBtn} type="button">Help</button>} modal>
            {close => (
                <div className="modal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>

                    <div className={styles.helpSection}>
                        <h2>Dropping/Uploading Content into Opal:</h2>
                        <ul>
                            <li>
                                <b>Uploading Your Content:</b> Once logged in, navigate to the content upload section.
                            </li>
                            <li>
                                <b>Select Files:</b> In the content upload section, you will find the option to &quot;drag and drop&quot; files from your computer or manually select them using the &quot;Browse&quot; or &quot;Select Files&quot; button to manually choose the files you want to upload from your computer.
                                <br />
                                <br /> Before uploading your files, please note the following requirements:
                                <ul>
                                    <li>Files should be in one of the following formats:
                                        <ul>
                                            <li>.jpeg, .tif, .png, .indd, .zip, .XML, etc.</li>
                                            <li>Image files should be at least 600 dpi;</li>
                                            <li>Each file should be less than 50MB </li>
                                        </ul>
                                    </li>
                                    <li>Any file extension considered &quot;executable&quot;, which could contain a virus, cannot be uploaded. That list of types is:
                                        <ul>
                                            <li>386, ade, adp, app, asp, bas, bat, cer, CGI, chm, cmd, com, cpl, crt, csh, csr, DLL, db, drv, exe, fxp, hlp, hta, htaccess, htpasswd, inf, ins, isp, jar, js, jse, jsp, ksh, lnk, mdb, made, mdt, mdw, msc, msi, msp, most, ops, pcd, PHP, pif, pl, prg, reg, scr, sct, sh, shb, shs, sys, torrent, URL, vb, vbe, vbs, VBScript, wsc, wsf, wsh</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>File Type and Naming Validation Preview:</b>
                                <ul>
                                    <li>Files and folders should be named with the feature code followed by the date in the format of:
                                        <ul>
                                            <li>
                                                <b>Short Date:</b> feature code (xyz), year (yy), month (mm), and day (dd)
                                                <br /> Ex: xyz240501.tif
                                                <br /> OR
                                            </li>
                                            <li>
                                                <b>Long Date:</b> feature code (xyz), year (yyyy), month (mm), and day (dd)
                                                <br /> Ex: xyz20240501.tif
                                            </li>
                                        </ul>
                                    </li>
                                    <li>The system performs file validation check, including a check for compliance with the specified naming convention, and shows a preview list of validated files.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Change Files (If Necessary):</b>
                                <ul>
                                    <li>If any files do not pass the validation step, you'll have the option to change or remove them from the list before proceeding. </li>
                                </ul>
                            </li>
                            <li>
                                <b>Canceling Upload (Optional):</b>
                                <ul>
                                    <li>If you decide to cancel the upload process after selecting files, you can click the Cancel button below the list of files. Alternatively, if you wish to remove individual files before uploading, you can click the Trash Can icon to remove each files. </li>
                                </ul>
                            </li>
                            <li>
                                <b>Upload Content:</b>
                                <ul>
                                    <li>If you choose to proceed with the upload, click on the &quot;Upload&quot; button to start the upload process. </li>
                                    <li>After selecting the files, the system will begin uploading them automatically. You may see a progress bar indicating the status of each upload. </li>
                                </ul>
                            </li>
                            <li>
                                <b>Confirmation and Feedback:</b>
                                <ul>
                                    <li>Once the upload is complete, you should receive a confirmation message along with a timestamp indicating the successful upload. </li>
                                </ul>
                            </li>
                            <li>
                                <b>Downloading Previously Uploaded Files</b>
                                <ul>
                                    <li>If you wish to download a file that you have previously uploaded, you can click the Download arrow icon next to the file in the list of uploaded files.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>If you get stuck or have any questions, please review our FAQs below, or you can email our support team at <a href="mailto:opal_support@amuniversal.com" title="opal_support@amuniversal.com">opal_support@amuniversal.com</a>.
                        </p>

                    </div>

                    <div className={styles.helpSection}>
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            <b>Trouble signing in? </b> If you're having trouble signing
                            in, please ensure you are using the correct login credentials. If the issue
                            persists, click on &quot;Forgot Username/Password&quot; to recover your
                            username or reset your password.</p>
                        <p>
                            <b>Can I avoid typing my credentials each time? </b> Yes, by
                            selecting the &quot;Remember Me&quot; checkbox when signing in, you can save
                            your login credentials, so you won't have to type them again on your device for
                            quicker access next time.</p>
                        <p>
                            <b>What should I do if I forget my username or password? </b>
                            Click on the &quot;Forgot Username&quot; or &quot;Forgot Password&quot; link on
                            the sign-in page and follow the instructions to recover your username or reset
                            your password.</p>
                        <p>
                            <b>How do I know my files were uploaded successfully? </b>
                            Once a file is uploaded successfully, the status will be displayed in the
                            status column, with a successful file showing &quot;Completed.&quot;</p>
                        <p>
                            <b>What should I do if my upload fails? </b> If an upload
                            fails, try uploading the file again. If the issue continues, check your
                            internet connection. If the problem persists, click &quot;Contact Us&quot; to
                            report the issue.</p>
                        <p>
                            <b>What if I uploaded the wrong file? </b> If you uploaded
                            the wrong file, proceed to upload the correct file as usual. If the file name
                            is the same, our system will use the most recent version of that file.</p>
                        <p>
                            <b>How can I change my email address or password? </b>
                            Navigate to account settings after signing in to update your email address and
                            password, following the verification and criteria specified.</p>
                        <p>
                            <b>How do I select files for upload?</b> Drag and drop files
                            into the designated area or click &quot;Browse&quot; or &quot;Select
                            Files&quot; to manually select files from your computer.</p>
                        <p>
                            <b>Are there any restrictions on the types of files I can
                                upload? </b> Yes, the system validates files based on naming conventions and
                            file types. Ensure your files meet these requirements before uploading.</p>
                        <p>
                            <b>How can I remove or change files after selection but
                                before uploading? </b> In the preview list, you can remove or change selected
                            files before finalizing the upload by clicking the remove/change option next to
                            the file.</p>
                        <p>
                            <b>How long will my uploaded content be available in the
                                system? </b> The duration your content remains available is 2 weeks. </p>
                        <p>
                            <b>Can I upload more files after completing an upload
                                session? </b> Yes, after a successful upload, you will be given the option to
                            upload more files. Follow the prompts to continue adding content.</p>
                        <p>
                            <b>What should I do if I experience technical difficulties
                                with the CIS? </b> First, check your internet connection and browser
                            compatibility. If problems persist, send email to <a href="mailto:opal_support@amuniversal.com" title="opal_support@amuniversal.com">opal_support@amuniversal.com</a> for assistance.</p>
                        <p>
                            <b>Is my data secure when using the CIS? </b> The CIS uses
                            advanced encryption and secure data transmission methods to protect your data.
                            Ensure you're also following data security best practices on your end.</p>
                        <p>
                            <b>How can I improve my upload success rate? </b> Make sure
                            your files meet the system's criteria, you have a stable internet connection,
                            and try to upload during off-peak hours if possible.</p>
                        <p>
                            <b>Who can I contact for further support or feedback? </b>
                            For further support or to provide feedback,send email to <a href="mailto:opal_support@amuniversal.com" title="opal_support@amuniversal.com">opal_support@amuniversal.com</a>,
                            providing detailed information to assist you effectively.</p>
                        <p>
                            <b>How does the system validate files before uploading?</b>
                            The system checks each selected file against specific naming conventions and
                            file types to ensure compatibility and adherence to our standards. Only files
                            that meet these criteria will proceed to the upload stage.</p>
                        <p>
                            <b>What happens if a file doesn't pass the validation
                                process? </b> If a file fails the validation process, it will be marked, and you
                            will see a notification on the screen. You'll have the opportunity to remove or
                            replace the non-compliant file before proceeding with the upload.</p>
                        <p>
                            <b>Can I replace a file that failed validation? </b> Yes, you
                            can replace any file that fails validation. Simply remove the failed file from
                            the selection and then add the correct file by either dragging and dropping it
                            into the designated area or using the &quot;Browse&quot; or &quot;Select
                            Files&quot; button.</p>
                        <p>
                            <b>What should I do if I'm unsure why my file failed
                                validation? </b> If you're unsure about the reasons behind a validation failure,
                            refer to the specific error message provided or check our file preparation
                            guidelines. For further assistance, use the &quot;Contact Us&quot; option to
                            get help from our support team.</p>
                        <p>
                            <b>Are there any tips for ensuring my files pass validation? </b>
                            To ensure your files pass validation:</p>
                        <ul>
                            <li>Follow the recommended naming conventions, avoiding
                                special characters and spaces.&nbsp;</li>
                            <li>Check that your file types are supported by our
                                system.&nbsp;</li>
                            <li>Ensure your files do not exceed any size limits
                                specified.&nbsp;</li>
                            <li>Review file content to ensure compliance with our content
                                guidelines.&nbsp;</li>
                        </ul>
                        <p>
                            <b>What if I accidentally replace a good file with a wrong
                                one during validation?</b> If you accidentally replace a file that had
                            previously passed validation with an incorrect one, you can simply remove the
                            incorrect file and re-add the original one, assuming it's still available on
                            your device. The system allows for adjustments until the final upload process
                            is initiated.</p>
                        <p></p>
                        <p>
                            <b>How long will I have access to my files on the FTP
                                server?</b> Once you have been set up with OPAL, you will no longer have access to the FTP server.
                        </p>
                        <p>
                            <b>What will happen to the files I recently uploaded via
                                FTP? </b> After your setup with OPAL, FTP access will be disabled. We will address any issues that arise during the transition from FTP to OPAL.
                        </p>
                        <p>
                            <b>Can I use both the FTP and OPAL to send files?</b> No, once OPAL is set up, FTP access will be discontinued.
                        </p>
                        {/*<p>*/}
                        {/*    <b>I never used FTP to send my files. How do I obtain new*/}
                        {/*        login credentials?</b>*/}
                        {/*</p>*/}
                        <p>
                            <b>I prefer using email to send my files directly to my
                                editor, can I still do that?</b> Yes, you may continue to use email to send files directly to your editor.
                        </p>
                    </div>



                </div>
            )}
        </Popup>
    );
}

export default Help;
