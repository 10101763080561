import { signIn, signOut } from 'next-auth/react';
import { B2C } from 'src/constants';

let deployEnv = `${process.env.NEXT_PUBLIC_DEPLOY_ENV}`;
if (deployEnv === "production") deployEnv = "";

const tenantName = `${deployEnv}${B2C.TENANT_NAME}`;
const customPolicy = [B2C.POLICY_PREFIX, B2C.PRODUCT_KEY, B2C.POLICY_NAME].join(
    '_',
);
export const oauthUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${customPolicy}/oauth2/v2.0`;

const userSignIn = async () => {
    await signIn('azureb2c', undefined, {
        p: customPolicy,
    });
};

const userSignOut = async () => {
    const signOutRedirect = window.location.href;
    const signOutUrl = `${oauthUrl}/logout?post_logout_redirect_uri=${signOutRedirect}`;

    await signOut();
    window.location.href = signOutUrl;
};


export {
    userSignIn,
    userSignOut
};
