
export const B2C = {
    // All times in seconds and use Azure's default values from:
    // https://learn.microsoft.com/en-us/azure/active-directory-b2c/configure-tokens?pivots=b2c-custom-policy#token-lifetime-behavior
    REFRESH_TOKEN_AGE: 14 * 24 * 60 * 60, // 14 days
    ID_TOKEN_AGE: 60 * 60, // 60 minutes
    PRODUCT_KEY: 'CONTENT_INGESTION_SYSTEM',
    TENANT_NAME: 'amub2c',
    POLICY_PREFIX: 'B2C_1',
    POLICY_NAME: 'SIGNIN',
};

export const APPLICATION = {
    NAME: 'Content_Ingestion_System',
    ID: 'CIS',
};

export const REDIRECT_OVERRIDES = {
    SOURCE: [
        '/reset-password',
        // '/signup',
        '/signin',
        '/signout',
        // '/subscribe',
        // '/create-account/success',
        // '/create-account/age-restriction',
        // '/subscribe',
        // '/404',
    ],
    DESTINATION: '/',
};